<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h1 class="highlighted-color1">
          <span>{{ isEditPage ? $t('edit') : $t('create') }} {{ $t('segmentation_strategy') }}</span> <span v-b-tooltip.hover :title="tooltips.lines"><i class="fa fa-info-circle font-size-medium"></i></span>
        </h1>
      </div>
      <div class="d-flex">
        <Button
          class="btn btn-primary"
          v-b-modal.cancel-campaign-upsert-modal
        >
          {{ $t('cancel') }}
        </Button>
        <CancelCampaignUpsertModal
          :campaign="campaign"
          :segmentation="segmentation"
          :line="line"
          :step="isEditPage ? 'line' : 'package'"
        />
      </div>
    </div>
    <!-- CAMPAIGN LOADER -->
    <div v-if="campaignLoader" class="text-center mt-3">
      <Loader
        :size="'big'"
      />
    </div><!-- END CAMPAIGN LOADER -->
    <template v-else>
      <!-- PACKAGE FORM -->
      <div class="mt-3" v-if="!isEditPage || (isEditPage && line)">
        <!-- HEADING -->
        <section class="my-4">
          <div class="col-lg-8 mx-auto text-center">
            <p>{{ $t('configure_segmentation_strategy_campaign_group') }}</p>
          </div>
        </section> <!-- END HEADING -->
        <section class="">
          <div class="col-lg-8 mx-auto mb-5 position-relative">
            <ProgramaticCampaignNavigation
              :campaign="campaign"
              :line="line"
              :segmentation="segmentation"
              step="line"
            />
            <div class="card">
              <div class="card-body">
                <form @submit.prevent="" class="default-form">
                  <!-- NAME -->
                  <div class="form-group mb-3">
                    <label class="form-label fw-bold">{{ $t('strategy_name') }}*</label>
                    <input type="text" class="form-control" :placeholder="$t('strategy_name')" v-model.trim="form.name" />
                  </div> <!-- END NAME -->
                  <div class="d-flex">
                    <!-- TYPE -->
                    <div class="form-group mb-3 col-lg-6 me-2">
                      <label class="form-label fw-bold w-100 d-block">Tipo de estratégia*</label>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          :id="`type-display`"
                          value="DISPLAY"
                          v-model="form.type"
                          :disabled="isEditPage"
                        >
                        <label class="form-check-label" :for="`type-display`">Display</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          :id="`type-video`"
                          value="DISPLAY_VIDEO"
                          v-model="form.type"
                          :disabled="isEditPage"
                        >
                        <label class="form-check-label" :for="`type-video`">Vídeo</label>
                      </div>
                    </div> <!-- END TYPE -->
                  </div>
                  <div class="mb-3">
                     <!-- COUNTRIES -->
                     <div class="form-group mb-3 col-12 col-lg-12 me-2">
                      <div class="d-flex align-items-center mb-2">
                        <label class="form-label fw-bold mb-0">
                          {{ $t('countries') }}
                          <span v-b-tooltip.hover :title="tooltips.locations"><i class="fa fa-info-circle font-size-regular"></i></span>
                        </label>
                      </div>
                      <template v-if="countriesLoader">
                        <Loader
                          :size="'small'"
                          :align="'start'"
                        />
                      </template>
                      <template v-else>
                        <Multiselect
                          v-model="selectedCountries"
                          :placeholder="$t('search2')"
                          label="label"
                          track-by="code"
                          :options="countryOptions"
                          :multiple="true"
                        />
                      </template>
                    </div> <!-- END COUNTRIES -->
                    <div class="d-flex flex-wrap flex-lg-nowrap">
                      <!-- STATES -->
                      <div class="form-group col-12 col-lg-6 me-2">
                        <div class="d-flex align-items-center mb-2">
                          <label class="form-label fw-bold mb-0">
                            {{ $t('states') }}
                            <span v-b-tooltip.hover :title="tooltips.locations"><i class="fa fa-info-circle font-size-regular"></i></span>
                          </label>
                          <Button
                            class="btn-xs btn-primary py-1 ms-2"
                            @click="selectAllStates"
                            v-if="stateOptions.length > 0"
                          >
                            {{ $t('select_all') }}
                          </Button>
                        </div>
                        <template v-if="statesLoader">
                          <Loader size="small" :align="'start'" />
                        </template>
                        <template v-else>
                          <Multiselect
                            v-model="form.states"
                            :placeholder="$t('search2')"
                            label="label"
                            track-by="code"
                            :options="stateOptions"
                            :multiple="true"
                            :disabled="selectedCountries.length == 0"
                          />
                          <div
                              class="mt-2 alert-message-card text-color2 font-size-small px-3 py-2"
                              v-if="selectedCountries.length == 0"
                            >
                              <p
                                class="mb-0"
                              >
                                {{ $t('select_at_least_one_country_for_state_selection') }}
                              </p>
                          </div>
                        </template>
                      </div> <!-- END STATES -->
                      <!-- CITIES -->
                      <div class="form-group col-12 col-lg-6">
                        <label class="form-label fw-bold">
                          {{ $t('cities') }}
                          <span v-b-tooltip.hover :title="tooltips.locations"><i class="fa fa-info-circle font-size-regular"></i></span>
                        </label>
                        <template v-if="citiesLoader">
                          <Loader size="small" :align="'start'" />
                        </template>
                        <template v-else>
                          <Multiselect
                            v-model="form.cities"
                            :placeholder="$t('search2')"
                            label="label"
                            track-by="code"
                            :options="citiesOptions"
                            :multiple="true"
                            :disabled="selectedCountries.length == 0"
                          />
                          <div
                            class="mt-2 alert-message-card text-color2 font-size-small px-3 py-2"
                            v-if="selectedCountries.length == 0"
                          >
                            <p
                              class="mb-0"
                            >
                              {{ $t('select_at_least_one_country_for_city_selection') }}
                            </p>
                          </div>
                        </template>
                      </div> <!-- END CITIES -->
                    </div>
                    <div v-if="showAlertOnCitiesAndStatesSelection && (!citiesLoader && !statesLoader)">
                      <p class="fw-bold alert-message-card text-color2 font-size-xSmall mt-2" v-html="showAlertOnCitiesAndStatesSelection">
                      </p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <!-- BROWSER -->
                    <div class="form-group mb-3 col-lg-6 me-2">
                      <label class="form-label fw-bold w-100 d-block">{{ $t('devices') }}*</label>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`browser-mobile`"
                          value="MOBILE"
                          v-model="form.browser"
                        >
                        <label class="form-check-label" :for="`browser-mobile`">{{ $t('mobile') }}</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`browser-desktop`"
                          value="DESKTOP"
                          v-model="form.browser"
                        >
                        <label class="form-check-label" :for="`browser-desktop`">{{ $t('desktop') }}</label>
                      </div>
                    </div> <!-- END BROWSER -->
                  </div>
                  <!-- PREMIUM AUDIENCE LIST -->
                  <div class="form-group mb-3">
                    <label class="form-label fw-bold w-100 d-block">
                      {{ $t('broad_audience_list') }}
                      <span v-b-tooltip.hover :title="tooltips.platform_audiences"><i class="fa fa-info-circle font-size-regular"></i></span>
                      <Button @click.prevent="" class="btn-xs btn-primary py-1 ms-2" v-b-modal="'select-platform-audience-modal'">{{ $t('select_audiences') }}</Button>
                    </label>
                    <template v-if="form.platform_audiences.length === 0">
                      <p>{{ $t('zero_selected_audiences') }}</p>
                    </template>
                    <template v-else>
                      <ul>
                        <li class="d-flex justify-content-between align-items-center mb-3 col-lg-10" v-for="(audience, index) in form.platform_audiences" :key="index">
                          <span class="col-lg-11">
                            <span class="d-flex align-items-center">
                              {{ audience.name}} <span class="star-icon ms-2 font-size-xSmall"><i class="fa fa-star"></i></span>
                            </span>
                          </span>
                          <div class="col-lg-1 ms-2">
                            <Button
                              class="btn-xs btn-circle btn-danger font-size-small p-0"
                              @click="destroyAudience(index, 'PLATFORM_DATA')"
                              v-b-tooltip.hover :title="$t('remove')"
                            >
                              <i class="fa fa-trash"></i>
                            </Button>
                          </div>
                        </li>
                      </ul>
                    </template>
                    <SelectAudienceModal
                      :categoryId="campaign.category.id"
                      :selected="form.platform_audiences"
                      type="PLATFORM_DATA"
                      @audiences-selected="handleAudienceListSelected(...arguments, 'PLATFORM_DATA')"
                    />
                  </div> <!-- END PREMIUM AUDIENCE LIST -->
                  <!-- AUDIENCE LIST -->
                  <div class="form-group mb-3">
                    <label class="form-label fw-bold w-100 d-block">
                      {{ $t('segmented_audience_list') }}
                      <span v-b-tooltip.hover :title="tooltips.audiences"><i class="fa fa-info-circle font-size-regular"></i></span>
                      <Button @click.prevent="" class="btn-xs btn-primary py-1 ms-2" v-b-modal="'select-audience-modal'" v-if="campaign.is_third_party_audiences_enabled">{{ $t('select_audiences') }}</Button>
                    </label>
                    <template v-if="!campaign.is_third_party_audiences_enabled">
                      <div
                        class="mb-2 alert-message-card text-color2 font-size-small px-3 py-2"
                      >
                        <p class="mb-0">{{ $t('only_broad_audiences_available_for_advertisers_less_than_4_days') }}</p>
                      </div>
                    </template>
                    <template v-else-if="form.audiences.length === 0">
                      <p>{{ $t('zero_selected_audiences') }}</p>
                    </template>
                    <template v-else>
                      <ul>
                        <li class="d-flex justify-content-between align-items-center mb-3 col-lg-10" v-for="(audience, index) in form.audiences" :key="index">
                          <span class="col-lg-11">
                            {{ audience.name}}
                          </span>
                          <div class="col-lg-1 ms-2">
                            <Button
                              class="btn-xs btn-circle btn-danger font-size-small p-0"
                              @click="destroyAudience(index, 'THIRD_PARTY')"
                              v-b-tooltip.hover :title="$t('remove')"
                            >
                              <i class="fa fa-trash"></i>
                            </Button>
                          </div>
                        </li>
                      </ul>
                    </template>
                    <SelectAudienceModal
                      :categoryId="campaign.category.id"
                      :selected="form.audiences"
                      type="THIRD_PARTY"
                      @audiences-selected="handleAudienceListSelected(...arguments, 'THIRD_PARTY')"
                    />
                  </div> <!-- END AUDIENCE LIST -->
                  <!-- CUSTOM AUDIENCES LIST -->
                  <div class="form-group mb-3">
                    <label class="form-label fw-bold w-100 d-block">
                      {{ $t('own_audience_list') }} <a href="#" @click.prevent="" class="fw-normal ms-2" v-b-modal.select-custom-targeting-emails-modal v-if="campaign.is_custom_audiences_enabled">{{ $t('edit') }}</a>
                    </label>
                    <template v-if="!campaign.is_custom_audiences_enabled">
                      <p>{{ $t('own_audiences_only_available_for_advertisers_over_3_months') }}</p>
                    </template>
                    <template v-else-if="form.custom_audiences.length === 0">
                      <p>{{ $t('zero_own_audiences_selected') }}</p>
                    </template>
                    <template v-else>
                      <ul>
                        <li class="d-flex justify-content-between align-items-center mb-3 col-lg-7" v-for="(audience, index) in form.custom_audiences" :key="index">
                          <span>
                            {{ audience.name}}
                          </span>
                          <div>
                            <Button
                              class="btn-xs btn-circle btn-danger font-size-small p-0"
                              @click="destroyCustomTargetings(index, 'custom_audiences')"
                              v-b-tooltip.hover :title="$t('remove')"
                            >
                              <i class="fa fa-trash"></i>
                            </Button>
                          </div>
                        </li>
                      </ul>
                    </template>
                    <SelectCustomTargetingModal
                      :advertiser="campaign.advertiser"
                      modalId="select-custom-targeting-emails-modal"
                      type="EMAIL_AUDIENCE"
                      @custom-targeting-selected="handleCustomAudienceSelected"
                    />
                  </div> <!-- END CUSTOM AUDIENCES LIST -->
                  <div>
                    <p :class="{
                      'mb-0': audienceCount > 0
                    }"><strong>{{ $t('campaign_line_audience_limit_title', { audienceCount: audienceCount,  audienceCountMax: 10 }) }}</strong></p>
                    <div v-if="audienceCount > 10" class="fw-bold error-message-card text-white font-size-xSmall my-2">
                      <span v-html="$t('campaign_line_audience_limit_error.0', { audienceCountMax: 10 })">
                      </span>
                      <a href='#' @click.prevent='' class='text-white' v-b-modal.support-modal><strong>{{ $t('campaign_line_audience_limit_error.1') }}</strong></a>
                      {{ $t('campaign_line_audience_limit_error.2') }}
                    </div>
                  </div>
                  <!-- BLACKLIST -->
                  <div class="form-group mb-3">
                    <label class="form-label fw-bold w-100 d-block">
                      {{ $t('custom_targeting_type.blacklist') }}
                      <span v-b-tooltip.hover :title="tooltips.site_lists"><i class="fa fa-info-circle font-size-regular"></i></span>
                      <a href="#" @click.prevent="" class="fw-normal ms-2" v-b-modal.select-custom-targeting-blacklist-modal>{{ $t('edit') }}</a>
                    </label>
                    <template v-if="form.blacklist.length === 0">
                      <p>{{ $t('zero_lists_selected') }}</p>
                    </template>
                    <template v-else>
                      <ul>
                        <li class="d-flex justify-content-between align-items-center mb-3 col-lg-7" v-for="(list, index) in form.blacklist" :key="index">
                          <span>
                            {{ list.name}}
                          </span>
                          <div>
                            <Button
                              class="btn-xs btn-circle btn-danger font-size-small p-0"
                              @click="destroyCustomTargetings(index, 'blacklist')"
                              v-b-tooltip.hover :title="$t('remove')"
                            >
                              <i class="fa fa-trash"></i>
                            </Button>
                          </div>
                        </li>
                      </ul>
                    </template>
                    <SelectCustomTargetingModal
                      :advertiser="campaign.advertiser"
                      modalId="select-custom-targeting-blacklist-modal"
                      type="SITE_LIST"
                      @custom-targeting-selected="handleBlacklistSelected"
                    />
                  </div> <!-- END BLACKLIST -->
                  <!-- WISHLIST -->
                  <div class="form-group mb-3">
                    <label class="form-label fw-bold w-100 d-block">
                      {{ $t('custom_targeting_type.wishlist') }}
                      <span v-b-tooltip.hover :title="tooltips.site_lists"><i class="fa fa-info-circle font-size-regular"></i></span>
                      <a href="#" @click.prevent="" class="fw-normal ms-2" v-b-modal.select-custom-targeting-wishlist-modal>{{ $t('edit') }}</a>
                    </label>
                    <template v-if="form.wishlist.length === 0">
                      <p>{{ $t('zero_lists_selected') }}</p>
                    </template>
                    <template v-else>
                      <ul>
                        <li class="d-flex justify-content-between align-items-center mb-3 col-lg-7" v-for="(list, index) in form.wishlist" :key="index">
                          <span>
                            {{ list.name}}
                          </span>
                          <div>
                            <Button
                              class="btn-xs btn-circle btn-danger font-size-small p-0"
                              @click="destroyCustomTargetings(index, 'wishlist')"
                              v-b-tooltip.hover :title="$t('remove')"
                            >
                              <i class="fa fa-trash"></i>
                            </Button>
                          </div>
                        </li>
                      </ul>
                    </template>
                    <SelectCustomTargetingModal
                      :advertiser="campaign.advertiser"
                      modalId="select-custom-targeting-wishlist-modal"
                      type="SITE_LIST"
                      :withGlobal="true"
                      @custom-targeting-selected="handleWishlistSelected"
                    />
                  </div> <!-- END WISHLIST -->
                  <!-- CREATIVES -->
                  <div>
                    <!-- CREATIVES HEADER -->
                    <div
                      class="mb-4"
                    >
                      <div class="d-flex align-items-center justify-content-between">
                        <div>
                          <h3 class="highlighted-color1 font-size-medium">
                            <span>{{ $t('creatives') }}</span> <span v-b-tooltip.hover :title="tooltips.creatives"><i class="fa fa-info-circle font-size-regular"></i></span>
                          </h3>
                        </div>
                        <div>
                          <Button
                            class="btn btn-sm btn-primary me-3"
                            v-b-modal.select-creative-modal
                          >
                            {{ $t('add_new_creative') }}
                          </Button>
                        </div>
                      </div>
                      <p class="font-size-xSmall fw-bold mt-2" v-if="form.type === 'DISPLAY'">{{ $t('mandatory_creative_300x250') }}</p>
                    </div> <!-- END CREATIVES HEADER -->
                    <!-- CREATIVE LIST -->
                    <p v-if="form.creatives.length === 0">{{ $t('no_creatives_selected') }}</p>
                    <div class="col-lg-8" v-else>
                      <!-- CREATIVE -->
                      <div
                        class="d-flex creative-item flex-wrap align-items-center mb-3"
                        v-for="(creative, index) in form.creatives"
                        :key="index"
                      >
                        <div class="col-lg-3">
                          <img :src="creative.file" alt="" style="max-width: 100px;" />
                        </div>
                        <div class="col-lg-8 text-start">
                          <p class="mb-0">{{ creative.name }}</p>
                          <p class="mb-0">{{ creative.format | formatCreativeFormat }} | {{ creative.layout }}</p>
                        </div>
                        <div class="col-lg-1">
                          <Button
                            class="btn-xs btn-circle btn-danger font-size-small p-0"
                            @click="openDestroyCreativeModal(creative)"
                            v-b-tooltip.hover :title="$t('remove_creative')"
                          >
                            <i class="fa fa-trash"></i>
                          </Button>
                        </div>
                      </div> <!-- END CREATIVE -->
                    </div> <!-- END CREATIVE LIST -->
                    <DestroyCreativeModal
                      :creative="selectedCreative"
                      @creative-destroyed="destroyCreative"
                    />
                    <SelectCreativeModal
                      :advertiserId="campaign.advertiser.id"
                      :format="form.type"
                      @creative-selected="handleSelectedCreative"
                    />
                  </div> <!-- END CREATIVES -->
                  <!-- ACTIONS -->
                  <div class="text-center mt-3">
                    <!-- MESSAGES -->
                    <div
                      class="mb-2 alert-message-card text-color2 font-size-small px-3 py-2"
                      v-if="loader"
                    >
                      <p
                        class="mb-0"
                      >
                        {{ $t('this_action_may_take_a_few_minutes') }}
                      </p>
                    </div> <!-- END MESSAGES -->
                    <Button
                      class="btn btn-primary"
                      @click="submit"
                      :loading="loader"
                      :disabled="!isFormComplete"
                    >
                      {{ isEditPage ? this.$t('save_strategy') : this.$t('create_strategy') }}
                    </Button>
                  </div> <!-- END ACTION -->
                </form>
              </div>
            </div>
          </div>
        </section>
      </div><!-- END PACKAGE FORM -->
    </template>
  </div>
</template>

<script>

import CampaignsService from '@/modules/campaigns/services/campaigns-service'
import CampaignLinesService from '@/modules/campaigns/services/campaign-lines-service'
import HelpersService from '@/modules/helpers/services/helpers-service'
import variables from '@/mixins/variables'

import Button from '@/components/common/Button/Button'
import CancelCampaignUpsertModal from '@/components/campaigns/CancelCampaignUpsertModal'
import DestroyCreativeModal from '@/components/campaigns/UpsertForm/DestroyCreativeModal'
import Loader from '@/components/common/Loader/Loader'
import Multiselect from 'vue-multiselect'
import SelectAudienceModal from '@/components/audiences/SelectAudienceModal'
import SelectCreativeModal from '@/components/creatives/SelectCreativeModal'
import SelectCustomTargetingModal from '@/components/custom-targetings/SelectCustomTargetingModal'
import ProgramaticCampaignNavigation from '@/components/campaigns/Programatic/ProgramaticCampaignNavigation'

import { mapActions, mapState } from 'vuex'

export default {
  mixins: [variables],
  components: {
    Button,
    CancelCampaignUpsertModal,
    DestroyCreativeModal,
    Loader,
    Multiselect,
    SelectAudienceModal,
    SelectCreativeModal,
    SelectCustomTargetingModal,
    ProgramaticCampaignNavigation
  },
  data () {
    return {
      campaign: '',
      campaignLoader: false,
      cities: [],
      citiesLoader: false,
      countries: [],
      countriesLoader: false,
      form: {
        package_id: '',
        name: '',
        type: 'DISPLAY',
        platform_audiences: [],
        audiences: [],
        states: [],
        cities: [],
        browser: [],
        custom_audiences: [],
        wishlist: [],
        blacklist: [],
        creatives: []
      },
      line: '',
      loader: false,
      selectedCountries: [],
      states: [],
      statesLoader: false,
      segmentation: '',
      selectedCreative: ''
    }
  },
  metaInfo () {
    return {
      title: this.pageTitle
    }
  },
  created () {
    this.getCampaign()
  },
  watch: {
    /**
     * Watch for changes to countries
     */
    countries (newValue, oldValue) {
      // If is edit page, set selected countries
      if (this.isEditPage) this.setSelectedCountries()
    },
    /**
     * Watch for changes to selected countries
     */
    selectedCountries (newValue, oldValue) {
      this.getStates()
      this.getCities()
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    /**
     * Audience count
    */
    audienceCount () {
      return this.form.platform_audiences.length + this.form.audiences.length
    },
    /**
     * Get cities options based on the selected states of the line
     */
    citiesOptions () {
      /* const states = this.form.states.map(state => {
        return state.label
      }) */
      const cities = this.cities.reduce((filtered, item) => {
        /* if (states.includes(item.state_name)) {
          filtered.push({
            label: `${item.name} (${item.state_short_name})`,
            code: item.id
          })
        } */
        filtered.push({
          label: `${item.name} (${item.state_short_name} - ${item.country_short_name})`,
          code: item.id
        })
        return filtered
      }, [])
      return cities
    },
    /**
     * Get countries options
     */
    countryOptions () {
      return this.countries.map(item => {
        return {
          label: `${item.name} (${item.short_name})`,
          code: item.id
        }
      })
    },
    /**
     * Check if is edit page
     */
    isEditPage () {
      return this.$route.name === 'lines.edit'
    },
    /**
     * Is form complete
     */
    isFormComplete () {
      let complete = true
      if (!this.form.name || this.form.browser.length === 0 || this.form.creatives.length === 0) complete = false

      let format = null
      let hasValidLayout = false
      this.form.creatives.forEach(creative => {
        if (!format) {
          // Define the format as the first creative
          format = creative.format
        } else if (creative.format !== format) {
          // If any creatives have different format, it's invalid
          complete = false
        }

        // If is DISPLAY, check if has any 300x250 banners
        if (creative.format === 'DISPLAY' && creative.layout.toLowerCase() === '300x250') {
          hasValidLayout = true
        }
      })

      if (format === 'DISPLAY' && !hasValidLayout) {
        complete = false
      }

      return complete
    },
    /**
     * Get page title
     */
    pageTitle () {
      return this.$route.name === 'lines.create' ? this.$t('create_strategy') : this.$t('edit_strategy')
    },
    /**
     * show alert on cities and states selection
     */
    showAlertOnCitiesAndStatesSelection () {
      const selectedStates = this.form.states.map((state) => {
        const stateData = this.states.find((s) => s.id === state.code)
        return stateData?.name || ''
      })

      const selectedCities = this.form.cities.map((city) => {
        const cityData = this.cities.find((c) => c.id === city.code)
        return cityData ? { name: cityData.name, state_name: cityData.state_name } : null
      }).filter(city => city !== null)

      if (selectedStates.length && selectedCities.length) {
        // Check if the cities are within the selected state
        const citiesOutOfState = selectedCities.filter((city) =>
          !selectedStates.includes(city.state_name)
        )

        if (citiesOutOfState.length) {
          return this.$t('region_selection_states_and_cities', {
            states: selectedStates.join(', '),
            cities: [...new Set(citiesOutOfState.map((city) => city.state_name))].join(', ')
          })
        } else {
          return this.$t('region_selection_city_inside_state')
        }
      }

      if (selectedStates.length > 0) {
        return this.$t('region_selection_only_states')
      }

      if (selectedCities.length > 0) {
        return this.$t('region_selection_only_cities')
      }
      return false
    },
    /**
     * State options for select 2 field
     */
    stateOptions () {
      return this.states.map(state => {
        return {
          label: `${state.name} (${state.country_short_name})`,
          code: state.id
        }
      })
    }
  },
  methods: {
    ...mapActions('campaigns', [
      'setCurrentCampaign'
    ]),
    /**
     * Destroy audience
     */
    destroyAudience (index, type) {
      if (type === 'PLATFORM_DATA') this.form.platform_audiences.splice(index, 1)
      else this.form.audiences.splice(index, 1)
    },
    /**
     * Destroy creative
     */
    destroyCreative (creative) {
      const index = this.form.creatives.findIndex(item => {
        return item.id === creative.id
      })
      if (index < 0) return
      this.form.creatives.splice(index, 1)
    },
    /**
     * Destroy custom targetings
     */
    destroyCustomTargetings (index, type) {
      if (type === 'blacklist') {
        this.form.blacklist.splice(index, 1)
      } else if (type === 'wishlist') {
        this.form.wishlist.splice(index, 1)
      } else if (type === 'custom_audiences') {
        this.form.custom_audiences.splice(index, 1)
      }
    },
    /**
     * Get campaign
     */
    async getCampaign () {
      this.campaignLoader = true
      try {
        const campaign = await CampaignsService.getCampaign(this.$route.params.cId)
        if (campaign.status === 'FINISHED') return this.$router.push({ name: 'campaigns.show', params: { id: campaign.id } })
        this.campaign = campaign
        this.form.campaign_id = campaign.id

        this.getCountries()

        // If is edit page, the package id is not on the url
        if (this.isEditPage) {
          this.setLine()
        } else {
          // If is create page, the package id is on the url
          this.segmentation = campaign.packages.find(item => {
            return item.id === this.$route.params.pId
          })
          this.form.package_id = this.segmentation.id

          // Check if there is model to replicate
          if (this.$route.query.replicate) {
            this.setLine(this.$route.query.replicate)
          }
        }
      } catch (error) {
        console.log(error)
        error.handleGlobally && error.handleGlobally()
        this.$router.push('/')
      }
      this.campaignLoader = false
    },
    /**
     * Get cities
     */
    async getCities () {
      this.citiesLoader = true
      try {
        const cities = await HelpersService.getCities({
          categoryId: this.campaign.category.id,
          countryId: this.selectedCountries.map(item => {
            return item.code
          })
        })
        this.cities = cities
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.citiesLoader = false
    },
    /**
     * Get countries
     */
    async getCountries () {
      this.countriesLoader = true
      try {
        const countries = await HelpersService.getCountries()
        this.countries = countries
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.countriesLoader = false
    },
    /**
     * Get states
     */
    async getStates () {
      this.statesLoader = true
      try {
        const states = await HelpersService.getStates({
          categoryId: this.campaign.category.id,
          country_id: this.selectedCountries.map(item => {
            return item.code
          })
        })
        this.states = states
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.statesLoader = false
    },
    /**
     * Handle audience list selected
     */
    handleAudienceListSelected (audiences, type) {
      if (type === 'PLATFORM_DATA') this.form.platform_audiences = [...audiences]
      else this.form.audiences = [...audiences]
      // this.form.audiences = audiences
    },
    /**
     * Handle blacklist selected
     */
    handleBlacklistSelected (list) {
      const index = this.form.blacklist.findIndex(item => {
        return item.id === list.id
      })
      if (index >= 0) return
      this.form.blacklist.push(list)
    },
    /**
     * Handle custom audience selected
     */
    handleCustomAudienceSelected (list) {
      const index = this.form.custom_audiences.findIndex(item => {
        return item.id === list.id
      })
      if (index >= 0) return
      this.form.custom_audiences.push(list)
    },
    /**
     * Handle selected creative
     */
    handleSelectedCreative (creatives) {
      // Check if creative is not already selected
      creatives.forEach(creative => {
        const index = this.form.creatives.findIndex(item => {
          return item.id === creative.id
        })
        if (index < 0) this.form.creatives.push(creative)
      })
    },
    /**
     * Handle wishlist selected
     */
    handleWishlistSelected (list) {
      const index = this.form.wishlist.findIndex(item => {
        return item.id === list.id
      })
      if (index >= 0) return
      this.form.wishlist.push(list)
    },
    /**
     * Open destroy creative modal
     */
    openDestroyCreativeModal (creative) {
      this.selectedCreative = creative
      this.$bvModal.show('destroy-creative-modal')
    },
    /**
     * Select all states
     */
    selectAllStates () {
      const all = []
      this.stateOptions.forEach(item => {
        all.push({
          label: item.label,
          code: item.code
        })
      })
      this.form.states = all
    },
    /**
     * Set line (if there is ID, should set a specific line)
     */
    setLine (id = null) {
      this.segmentation = this.campaign.packages.find(item => {
        return item.lines.find(line => {
          if (id) return line.id === id
          else return line.id === this.$route.params.id
        })
      })
      this.form.package_id = this.segmentation.id

      const line = this.segmentation.lines.find(item => {
        if (id) return item.id === id
        else return item.id === this.$route.params.id
      })

      // Set line
      if (!id) this.line = line

      this.form.name = line.name
      this.form.type = line.type
      this.form.browser = line.browser
      this.form.audiences = line.audiences.filter(item => {
        return item.type === 'THIRD_PARTY'
      })
      this.form.platform_audiences = line.audiences.filter(item => {
        return item.type === 'PLATFORM_DATA'
      })
      this.form.states = line.states.map(s => {
        return {
          label: `${s.name} (${s.country_short_name})`,
          code: s.id
        }
      })
      this.form.cities = line.cities.map(c => {
        return {
          label: `${c.name} (${c.state_short_name} - ${c.country_short_name})`,
          code: c.id
        }
      })
      if (this.campaign.is_custom_audiences_enabled) this.form.custom_audiences = line.custom_audiences
      this.form.blacklist = line.blacklist
      this.form.wishlist = line.wishlist
      this.form.creatives = line.creatives.map(c => {
        return {
          id: c.id,
          name: c.name,
          format: c.format,
          layout: c.layout,
          url: c.url,
          is_desktop: c.is_desktop,
          is_mobile: c.is_mobile,
          is_app: c.is_app,
          file: c.file
        }
      })

      // Set selected countries
      this.setSelectedCountries()
    },
    /**
     * Set selected countries
     */
    setSelectedCountries () {
      if (!this.line) return
      const countries = []
      this.line.states.forEach(item => {
        if (!countries.includes(item.country_short_name)) countries.push(item.country_short_name)
      })
      this.line.cities.forEach(item => {
        if (!countries.includes(item.country_short_name)) countries.push(item.country_short_name)
      })
      this.selectedCountries = this.countries.filter(item => {
        return countries.includes(item.short_name)
      }).map(item => {
        return {
          label: `${item.name} (${item.short_name})`,
          code: item.id
        }
      })
    },
    /**
     * Submit
     */
    async submit () {
      this.loader = true
      try {
        // Create copy of form that can be changed (object assign wont work for multi-layered objects)
        const form = JSON.parse(JSON.stringify(this.form))

        if (this.campaign.is_custom_audiences_enabled) {
          form.custom_audiences = this.form.custom_audiences.map(item => {
            return item.id
          })
        }

        form.blacklist = this.form.blacklist.map(item => {
          return item.id
        })

        form.wishlist = this.form.wishlist.map(item => {
          return item.id
        })

        form.audiences = this.form.audiences.map(item => {
          return item.id
        })

        form.platform_audiences = this.form.platform_audiences.map(item => {
          return item.id
        })

        form.creatives = this.form.creatives.map(item => {
          return item.id
        })

        form.cities = this.form.cities.map(item => {
          return item.code
        })

        form.states = this.form.states.map(state => {
          return state.code
        })

        form.audiences = [...form.audiences, ...form.platform_audiences]

        let line = null
        if (!this.line) {
          line = await CampaignLinesService.createCampaignLine(form)
        } else {
          line = await CampaignLinesService.updateCampaignLine(this.line.id, form)
        }
        this.setCurrentCampaign({ campaign: '' })
        this.$router.push({ name: 'lines.show', params: { cId: this.campaign.id, id: line.id } })
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    }
  }
}
</script>

<style lang="css" src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
  ::v-deep {
    .programatic-campaign-navigation {
      position: absolute;
      top: 0;
      left: -100px;
      z-index: 2;
      display: none;

      @include desktop {
        display: block;
      }
    }
  }

  .star-icon {
    width: 20px;
    height: 20px;
    display: flex;
    background: $color1;
    justify-content: center;
    align-items: center;
  }
</style>
